import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import IconSubnav from "../../assets/icons/how_works/3_icons_subnav.png";
import ImgText from "../../assets/images/how_works/3_eterna_text_2.png";
import ImgPhone1 from "../../assets/images/how_works/3_eterna_phone_5.png"
import ImgPhone2 from "../../assets/images/how_works/3_eterna_phone_6.png"
import ImgPhone3 from "../../assets/images/how_works/3_eterna_phone_4.png"
import IconArrowRight from "../../assets/icons/home/1_Icon_flecha_home.png";
import IconButtonAgent from "../../assets/icons/how_works/3_eterna_button.png";

const HowWorksCollaboration = () => {
    const navigate = useNavigate();
    
    return (
        <>
            <div className="pt-20 pb-12 lg:pb-16 xl:pb-20 px-20 lg:px-40 2xl:px-44">
                <div className="flex justify-between items-start">
                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Collaboration Workflow</p>
                    <img className="w-[250px] lg:w-[280px] xl:w-[400px] 3xl:w-[500px] xl:mr-16" src={IconSubnav} />
                </div>
                <div>
                    <p className="text-[14px] lg:text-[18px] xl:text-[22px]">How Eterna Works?</p>
                </div>
                <div>
                    <div className="pt-10 lg:pt-14 xl:pt-20">
                        <img className="w-[370px] lg:w-[450px] xl:w-[610px] 3xl:w-[720px]" src={ImgText} />
                    </div>
                    <div>
                        <div className="flex justify-center -mt-5 lg:-mt-7 xl:-mt-10">
                            <img className="w-[320px] lg:w-[400px] xl:w-[620px] 3xl:w-[720px]" src={ImgPhone1} />
                        </div>
                        <div className="flex justify-between">
                            <div className="-mt-24 lg:-mt-28 xl:-mt-40 3xl:-mt-48">
                                <img className="w-[220px] lg:w-[260px] xl:w-[400px] 3xl:w-[500px]" src={ImgPhone3} />
                            </div>
                            <div className="-mt-52 lg:-mt-64 xl:-mt-96 3xl:-mt-[470px] -mr-3 lg:-mr-12 xl:-mr-16 2xl:-mr-9 3xl:mr-7">
                                <img className="w-[220px] lg:w-[260px] xl:w-[400px] 3xl:w-[500px]" src={ImgPhone2} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pl-12 pt-16 lg:pt-20 xl:pt-28 flex justify-start items-center space-x-3">
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/team")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Team workflows</p>
                        <img className="w-[20px] lg:w-[30px] xl:w-[55px]" src={IconArrowRight} />
                    </button>
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/conversation")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Create conversation flow</p>
                        <img className="w-[20px] lg:w-[30px] xl:w-[55px]" src={IconArrowRight} />
                    </button>
                    <button onClick={() => navigate("/agents")}>
                        <img className="w-[110px] lg:w-[150px] xl:w-[250px]" src={IconButtonAgent} />
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HowWorksCollaboration;