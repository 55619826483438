import IconEterna from "../../assets/icons/home/1_icon_eterna.png";
import IconLinkedin from "../../assets/icons/home/1_icon_linkedin.png";
import IconWhatsapp from "../../assets/icons/home/1_icon_whatsapp.png";
import IconEterna2024 from "../../assets/icons/home/1_icon_eterna2024.png";
import IconPrivacy from "../../assets/icons/home/1_icon_privacy.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    
    return (
        <div className="bg-[#383737] flex justify-center items-center space-x-10 lg:space-x-16 py-12">
            <div>
                <img className="w-[110px] cursor-pointer" src={IconEterna} onClick={() => navigate("/")} />
            </div>
            <div>
                <a target="_blank" href="https://www.linkedin.com/company/eterna-digital/?originalSubdomain=mx">
                    <img className="w-[90px]" src={IconLinkedin} />
                </a>
            </div>
            <div>
                <img className="w-[100px]" src={IconWhatsapp} />
            </div>
            <div>
                <img className="w-[140px]" src={IconEterna2024} />
            </div>
            <div>
                <img className="w-[140px] cursor-pointer" src={IconPrivacy} onClick={() => navigate("/privacy_policy")} />
            </div>
        </div>
    );
}

export default Footer;