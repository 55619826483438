import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconEterna from "../../assets/icons/home/1_eterna_icon.png";
import Footer from "../../components/footer/Footer";
import IconFirst from "../../assets/icons/agents/4_eterna_agent_1.png";
import IconSecond from "../../assets/icons/agents/4_eterna_agent_2.png";
import IconThird from "../../assets/icons/agents/4_eterna_agent_3.png";
import IconFourth from "../../assets/icons/agents/4_eterna_agent_4.png";
import IconFifth from "../../assets/icons/agents/4_eterna_agent_5.png";
import IconSixth from "../../assets/icons/agents/4_eterna_agent_6.png";
import BtnFirst from "../../assets/icons/agents/4_eterna_btn_1.png";
import BtnSecond from "../../assets/icons/agents/4_eterna_btn_2.png";
import BtnThird from "../../assets/icons/agents/4_eterna_btn_3.png";
import BtnFourth from "../../assets/icons/agents/4_eterna_btn_4.png";
import BtnFifth from "../../assets/icons/agents/4_eterna_btn_5.png";
import BtnSixth from "../../assets/icons/agents/4_eterna_btn_6.png";

const Agents = () => {
    const navigate = useNavigate();
    const [agentElem] = useState([
        {
            name: "Finance",
            img: IconFirst,
            btn: BtnFirst,
            height: "h-[70px] lg:h-[100px] xl:h-[120px]",
            nvgte: "/agents/finance"
        },
        {
            name: "Human Resources",
            img: IconSecond,
            btn: BtnSecond,
            height: "h-[70px] lg:h-[100px] xl:h-[120px]",
            nvgte: "/agents/human"
        },
        {
            name: "Personal Flow",
            img: IconThird,
            btn: BtnThird,
            height: "h-[90px] lg:h-[120px] xl:h-[150px]",
            nvgte: "/agents/personal"
        },
        {
            name: "Profit Sharing",
            img: IconFourth,
            btn: BtnFourth,
            height: "h-[90px] lg:h-[120px] xl:h-[150px]",
            nvgte: "/agents/profit"
        },
        {
            name: "Legal",
            img: IconFifth,
            btn: BtnFifth,
            height: "h-[90px] lg:h-[120px] xl:h-[150px]",
            nvgte: "/agents/legal"
        },
        {
            name: "Business Productivity ",
            img: IconSixth,
            btn: BtnSixth,
            height: "h-[90px] lg:h-[120px] xl:h-[150px]",
            nvgte: "/agents/business"
        }
    ]);

    return (
        <>
            <div className="bg-eterna-p6 bg-center bg-cover">
                <div className="pt-20 pb-12 lg:pb-16 xl:pb-20">
                    <div className="px-20 lg:px-40 2xl:px-52">
                        <div className="flex justify-between items-start">
                            <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Eterna Agents</p>
                            <img
                                className="w-[80px] lg:w-[120px] xl:w-[160px] cursor-pointer"
                                src={IconEterna}
                                onClick={() => navigate("/")}
                            />
                        </div>
                        <div>
                            <p className="text-[14px] lg:text-[18px] xl:text-[22px]">All agents, one WhatsApp line</p>
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-3 pt-14 lg:pt-20 xl:pt-28 px-16 lg:px-24 xl:px-32">
                        {
                            agentElem.map((elem, i) => (
                                <div className="bg-white flex items-end px-2 pb-4 rounded-xl shadow-xl" key={i}>
                                    <div>
                                        <div className="pt-5 px-5">
                                            <img className={`w-auto m-auto ${ elem.height }`} src={elem.img} />
                                        </div>
                                        <div className="px-5 py-2">
                                            <p className="text-xs lg:text-lg xl:text-2xl">{ elem.name }</p>
                                        </div>
                                        <div className="px-5">
                                            <img
                                                src={elem.btn}
                                                className="cursor-pointer"
                                                onClick={() => navigate(elem.nvgte)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Agents;