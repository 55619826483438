import { Route, Routes } from "react-router-dom";
import Home from "../views/home/Home";

import LearnMore from "../views/learnmore/LearnMore";
import LearnMoreAgent from "../views/learnmore/LearnMoreAgent";
import LearnMoreAdoption from "../views/learnmore/LearnMoreAdoption.js";

import HowWorks from "../views/howworks/HowWorks.js";
import HowWorksTeam from "../views/howworks/HowWorksTeam.js";
import HowWorksCollaboration from "../views/howworks/HowWorksCollaboration.js";
import HowWorksConversation from "../views/howworks/HowWorksConversation.js";

import Agents from "../views/agents/Agents.js";
import AgentsFinance from "../views/agents/AgentsFinance.js";
import AgentsHuman from "../views/agents/AgentsHuman.js";
import AgentsPersonal from "../views/agents/AgentsPersonal.js";
import AgentsProfit from "../views/agents/AgentsProfit.js";
import AgentsLegal from "../views/agents/AgentsLegal.js";
import AgentsBusiness from "../views/agents/AgentsBusiness.js";
import Privacy from "../views/privacy_policy/Privacy.js";

const RoutesApp = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy_policy" element={<Privacy />} />

            <Route path="/learn_more" element={<LearnMore />} />
            <Route path="/learn_more/agent" element={<LearnMoreAgent />} />
            <Route path="/learn_more/adoption" element={<LearnMoreAdoption />} />

            <Route path="/how_works" element={<HowWorks />} />
            <Route path="/how_works/team" element={<HowWorksTeam />} />
            <Route path="/how_works/collaboration" element={<HowWorksCollaboration />} />
            <Route path="/how_works/conversation" element={<HowWorksConversation />} />

            <Route path="/agents" element={<Agents />} />
            <Route path="/agents/finance" element={<AgentsFinance />} />
            <Route path="/agents/human" element={<AgentsHuman />} />
            <Route path="/agents/personal" element={<AgentsPersonal />} />
            <Route path="/agents/profit" element={<AgentsProfit />} />
            <Route path="/agents/legal" element={<AgentsLegal />} />
            <Route path="/agents/business" element={<AgentsBusiness />} />
        </Routes>
    );
}

export default RoutesApp;