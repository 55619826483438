import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import IconSubnav from "../../assets/icons/how_works/3_icons_subnav.png";
import IconArrowRight from "../../assets/icons/home/1_Icon_flecha_home.png";
import IconButtonAgent from "../../assets/icons/how_works/3_eterna_button.png";
import ImgText from "../../assets/images/how_works/3_eterna_text_3.png";
import ImgBgColors from "../../assets/images/how_works/3_eterna_image_1.png";
import ImgPhone from "../../assets/images/how_works/3_eterna_phone_7.png";

const HowWorksConversation = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="pt-20 pb-12 lg:pb-16 xl:pb-20 px-20 lg:px-40 2xl:px-52">
                <div className="flex justify-between items-start">
                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Create conversation flow</p>
                    <img className="w-[230px] lg:w-[260px] xl:w-[350px] 3xl:w-[500px] xl:mr-16" src={IconSubnav} />
                </div>
                <div>
                    <p className="text-[14px] lg:text-[18px] xl:text-[22px]">How Eterna Works?</p>
                </div>
                <div className="relative">
                    <div className="pt-10 lg:pt-14 xl:pt-10 ml-8">
                        <img className="w-[370px] lg:w-[450px] xl:w-[650px] 3xl:w-[720px]" src={ImgText} />
                    </div>
                    <div className="-mt-20 xl:-mt-28 -ml-14 lg:-ml-16 xl:-ml-20">
                        <img className="w-[580px] lg:w-[650px] xl:w-[850px]" src={ImgBgColors} />
                    </div>
                    <div className="absolute top-0 right-0">
                        <img className="w-[250px] lg:w-[350px] xl:w-[450px] mt-56 lg:mt-64 xl:mt-72" src={ImgPhone} />
                    </div>
                </div>
                <div className="pl-12 pt-16 lg:pt-20 xl:pt-28 flex justify-start items-center space-x-3">
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/team")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Team workflows</p>
                        <img className="w-[20px] lg:w-[30px] xl:w-[55px]" src={IconArrowRight} />
                    </button>
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/collaboration")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Collaboration workflows</p>
                        <img className="w-[20px] lg:w-[30px] xl:w-[55px]" src={IconArrowRight} />
                    </button>
                    <button onClick={() => navigate("/agents")}>
                        <img className="w-[110px] lg:w-[150px] xl:w-[250px]" src={IconButtonAgent} />
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HowWorksConversation;