import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import IconEterna from "../../assets/icons/home/1_eterna_icon.png";
import ImgPhone from "../../assets/images/how_works/3_eterna_phone.png";
import ImgIcons from "../../assets/icons/how_works/3_icons_group.png";
import ImgWhatsApp from "../../assets/icons/how_works/3_icons_wa.png";
import ImgNotify from "../../assets/icons/how_works/3_icons_notify.png";
import IconArrowRight from "../../assets/icons/home/1_Icon_flecha_home.png";

const HowWorks = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-eterna-p5 bg-center bg-cover pt-14 pb-16 xl:pb-44 px-20 lg:px-40 xl:px-56">
                <div className="flex justify-end items-center pt-3 pr-28">
                    <img
                        className="w-[80px] lg:w-[120px] xl:w-[160px] cursor-pointer"
                        src={IconEterna}
                        onClick={() => navigate("/")}    
                    />
                </div>
                <div className="pt-6 lg:pt-8 xl:pt-14">
                    <div className="pt-20 pl-12">
                        <p className="text-[28px] lg:text-[35px] xl:text-[45px]">How Eterna Works?</p>
                    </div>
                    <div className="mt-12 ml-4">
                        <div className="relative bg-white px-9 pt-7 pb-24 lg:pb-28 xl:pb-40 rounded-xl shadow-md">
                            <div className="w-full flex justify-start items-center space-x-3">
                                <div>
                                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">01</p>
                                </div>
                                <div className="w-[100px] lg:w-[120px] xl:w-[150px]">
                                    <p className="text-[12px] lg:text-[15px] xl:text-[18px] font-light">A request is made to Eterna's Agent</p>
                                </div>
                            </div>
                            <div className="absolute top-0 right-0 -mt-36 lg:-mt-40 xl:-mt-48 2xl:-mt-64 mr-5">
                                <img className="w-[275px] lg:w-[315px] xl:w-[400px] 2xl:w-[455px]" src={ImgPhone} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-mt-10 xl:-mt-36 ml-4 px-20 lg:px-40 xl:px-56 space-y-6">
                <div className="relative bg-white px-9 pt-7 pb-24 lg:pb-28 xl:pb-40 rounded-xl shadow-md">
                    <div className="w-full flex justify-start items-center space-x-3">
                        <div>
                            <p className="text-[28px] lg:text-[35px] xl:text-[45px]">02</p>
                        </div>
                        <div className="w-[220px] lg:w-[280px] xl:w-[320px]">
                            <p className="text-[12px] lg:text-[15px] xl:text-[18px] font-light">
                                The request is analyzed by the AI and uses the onmichannel integration
                                to connect to the productivity software.
                            </p>
                        </div>
                    </div>
                    <div className="absolute right-0 2xl:-mt-8">
                        <img className="w-[300px] lg:w-[350px] xl:w-[480px] 2xl:w-[580px]" src={ImgIcons} />
                    </div>
                </div>
                <div className="relative bg-white px-9 pt-7 pb-28 lg:pb-32 xl:pb-44 rounded-xl shadow-md">
                    <div className="w-full flex justify-start items-center space-x-3">
                        <div>
                            <p className="text-[28px] lg:text-[35px] xl:text-[45px]">03</p>
                        </div>
                        <div className="w-[400px] lg:w-[500px] xl:w-[600px]">
                            <p className="text-[12px] lg:text-[15px] xl:text-[18px] font-light">
                                Then the agent processes the information and delivers an accurate
                                response, wich may include attachments, documents, or actions
                            </p>
                        </div>
                    </div>
                    <div className="absolute right-0 xl:-mt-3 2xl:-mt-10">
                        <img className="w-[90px] lg:w-[100px] xl:w-[130px] 2xl:w-[170px]" src={ImgWhatsApp} />
                    </div>
                    <div className="absolute right-0 mt-10 lg:mt-12 2xl:mt-6">
                        <img className="w-[380px] lg:w-[400px] xl:w-[550px] 2xl:w-[705px]" src={ImgNotify} />
                    </div>
                </div>
            </div>
            <div className="px-20 lg:px-48 xl:px-60 py-12 lg:py-12 xl:py-20 flex justify-start items-center space-x-5 xl:space-x-7">
                <button
                    className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                    onClick={() => navigate("/how_works/team")}
                >
                    <p className="text-[8px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-light">Team workflows</p>
                    <img className="w-[15px] lg:w-[35px] xl:w-[45px] 2xl:w-[55px]" src={IconArrowRight} />
                </button>
                <button
                    className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                    onClick={() => navigate("/how_works/collaboration")}
                >
                    <p className="text-[8px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-light">Collaboration workflows</p>
                    <img className="w-[15px] lg:w-[35px] xl:w-[45px] 2xl:w-[55px]" src={IconArrowRight} />
                </button>
                <button
                    className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                    onClick={() => navigate("/how_works/conversation")}
                >
                    <p className="text-[8px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-light">Create conversation flow</p>
                    <img className="w-[15px] lg:w-[35px] xl:w-[45px] 2xl:w-[55px]" src={IconArrowRight} />
                </button>
            </div>
            <Footer />
        </>
    );
}

export default HowWorks;