import { useNavigate } from "react-router-dom";
import IconEterna from "../../assets/icons/home/1_eterna_icon.png";
import IconArrow from "../../assets/icons/home/1_Icon_flecha_home.png";
import IconName from "../../assets/icons/home/1_icon_name.png";
import IconEnterprise from "../../assets/icons/home/1_icon_enterprise.png";
import IconMail from "../../assets/icons/home/1_icon_mail.png";
import IconDate from "../../assets/icons/home/1_icon_date.png";
import IconGmail from "../../assets/icons/home/1_icon_gmail.png";
import IconArrowDown from "../../assets/icons/home/1_icon_arrow_down.png";
import IconArrows from "../../assets/icons/home/1_icon_arrows.png";
import ImgPhone from "../../assets/images/home/1_phone_home.png";
import ImgPhone2 from "../../assets/images/home/1_phone_home_2.png";
import ImgLetters from "../../assets/images/home/1_letters_eterna.png";
import Footer from "../../components/footer/Footer";

const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-eterna-p2 bg-center bg-cover">
                <div className="grid md:grid-cols-2 pt-32 pb-64">
                    <div className="w-full flex justify-end backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[520px]">
                            <div className="pl-5 font-sans">
                                <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Omnichannel</p>
                                <p className="-mt-1 xl:-mt-3 text-[28px] lg:text-[35px] xl:text-[45px]">AI Agents unifying</p>
                                <p className="-mt-1 xl:-mt-3 text-[28px] lg:text-[35px] xl:text-[45px]">enterprise technologies</p>
                            </div>
                            <div className="pt-3 pb-6 pl-5">
                                <p className="text-[18px] lg:text-[22px] xl:text-[30px] font-light">that your workforce will love</p>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="flex justify-end backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[520px] relative pl-14 pr-10 py-8 lg:pt-10 xl:py-12 rounded-xl">
                            <div className="absolute inset-0 bg-opacity-25 w-full h-full bg-white rounded-l-xl border-l border-y border-white"></div>
                            <p className="relative text-[11.5px] lg:text-[15px] xl:text-[19px] font-light">
                                Imagine an agent that connects to existing systems and 
                                can respond instantly to team requests, pulling exactly
                                what's needed from those tools. Our agents automate processes,
                                track status updates, and manage operations.
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center bg-opacity-25 bg-white border-y border-white">
                        <img
                            className="w-[80px] lg:w-[120px] xl:w-[160px] ml-12 mb-14 cursor-pointer"
                            src={IconEterna}
                            onClick={() => navigate("/")}
                        />
                    </div>
                </div>
            </div>
            <div className="relative bg-eterna-p3 bg-center bg-cover">
                <div className="w-full -mt-40 lg:-mt-44 xl:-mt-48">
                    <div className="flex justify-center items-center">
                        <div className="w-full relative">
                            <div className="absolute w-[300px] ml-80 -inset-2 rounded-lg bg-gradient-to-r from-[#BC93F6] via-[#BC93F6] to-[#9BEBFF] opacity-10 blur"></div>
                            <div className="relative">
                                <div className="w-full flex justify-center items-center">
                                    <img className="w-[350px] lg:w-[380px] xl:w-[430px] mt-2 lg:ml-28 xl:ml-36" src={ImgPhone} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="w-[300px] lg:w-[400px] xl:w-[500px] bg-white space-y-3 p-5 rounded-xl">
                                <div
                                    className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/learn_more")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">Learn more</p>
                                    <img className="w-[40px] lg:w-[60px] xl:w-[80px]" src={IconArrow} />
                                </div>
                                <div
                                    className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/how_works")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">How Eterna works?</p>
                                    <img className="w-[40px] lg:w-[60px] xl:w-[80px]" src={IconArrow} />
                                </div>
                                <div
                                    className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/agents")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">Eterna Agents</p>
                                    <img className="w-[40px] lg:w-[60px] xl:w-[80px]" src={IconArrow} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-mt-10 flex justify-center items-center">
                    <img className="w-[15px] lg:w-[20px] xl:w-[30px]" src={IconArrowDown} />
                </div>
                <div className="mt-16">
                    <img className="w-[660px] lg:w-[750px] xl:w-[950px] m-auto" src={ImgLetters} />
                </div>
                <div className="mt-10 flex justify-center items-center">
                    <img className="w-[15px] lg:w-[20px] xl:w-[30px]" src={IconArrowDown} />
                </div>
                <div className="mt-5 mb-10 flex justify-center items-start">
                    <div className="w-full absolute mt-16 xl:mt-12 mr-36 lg:mr-44 xl:mr-68 2xl:mr-80">
                        <img
                            className="relative w-[380px] lg:w-[420px] xl:w-[505px] m-auto z-10"
                            src={IconArrows}
                        />
                    </div>
                    <div className="w-full relative">
                        <div className="relative">
                            <div className="w-full flex justify-center items-center">
                                <img className="w-[340px] lg:w-[370px] xl:w-[420px] mt-2 lg:ml-28 xl:ml-36" src={ImgPhone2} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="w-[250px] xl:w-[300px] mt-14 xl:mt-9 ml-32 xl:ml-40 2xl:ml-24 space-y-3">
                            <div className="w-full bg-white p-3 shadow-md">
                                <img className="w-full" src={IconName} />
                            </div>
                            <div className="w-full bg-white p-3 shadow-md">
                                <img className="w-full" src={IconEnterprise} />
                            </div>
                            <div className="w-full bg-white p-3 shadow-md">
                                <img className="w-full" src={IconMail} />
                            </div>
                            <div className="w-full bg-white p-3 shadow-md">
                                <img className="w-full" src={IconDate} />
                            </div>
                            <div className="w-full bg-white p-3 shadow-md">
                                <img className="w-[70px] xl:w-[100px] m-auto" src={IconGmail} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;