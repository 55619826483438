import { useNavigate } from "react-router-dom";
import IconEterna from "../../assets/icons/home/1_eterna_icon.png";
import Footer from "../../components/footer/Footer";
import ImgPhone from "../../assets/images/agents/4_eterna_phone_2.png";
import BtnFirst from "../../assets/icons/agents/4_eterna_btn_1.png";
import BtnThird from "../../assets/icons/agents/4_eterna_btn_3.png";
import BtnFourth from "../../assets/icons/agents/4_eterna_btn_4.png";
import BtnFifth from "../../assets/icons/agents/4_eterna_btn_5.png";
import BtnSixth from "../../assets/icons/agents/4_eterna_btn_6.png";
import BtnWA from "../../assets/icons/agents/4_eterna_btn_7.png";

const AgentsHuman = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="relative bg-eterna-p8 bg-center bg-cover">
                <div className="absolute right-0">
                    <img
                        className="w-[80px] lg:w-[120px] xl:w-[150px] mt-20 mr-60 cursor-pointer"
                        src={IconEterna}
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="grid md:grid-cols-2 pb-64">
                    <div className="w-full flex justify-end pt-56 backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[520px]">
                            <div className="pl-8 font-sans">
                                <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Human Resources</p>
                            </div>
                            <div className="pt-2 pb-12 pl-8">
                                <p className="text-[18px] lg:text-[22px] xl:text-[30px] font-light">Meet the HR agent.</p>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="flex justify-end backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[525px] relative pl-8 pr-10 py-8 lg:pt-10 xl:py-7 rounded-xl">
                            <div className="absolute inset-0 bg-opacity-25 w-full h-full bg-white rounded-l-xl border-l border-y border-white"></div>
                            <p className="relative text-[11.5px] lg:text-[15px] xl:text-[19px] font-light">
                                Hi! I'm your Eterna HR Agent, here to help you automate HR tasks like managing leave
                                requests, tracking employee performance, and generating reports-all through WhatsApp.
                            </p>
                            <p className="relative pt-8 text-[11.5px] lg:text-[15px] xl:text-[19px] font-light">
                                I can also send reminders for important HR deadlines and provide HR documents
                                when needed.
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center bg-opacity-25 bg-white border-y border-white"></div>
                </div>
            </div>
            <div className="relative">
                <div className="w-full -mt-52 lg:-mt-48 xl:-mt-72 mb-10">
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-start lg:-ml-10 xl:-ml-20">
                            <img className="w-[350px] lg:w-[380px] xl:w-[530px] m-auto" src={ImgPhone} />
                        </div>
                        <div className="space-y-1 -mt-8 ml-40">
                            <img
                                src={BtnFirst}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/finance")}
                            />
                            <img
                                src={BtnThird}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/personal")}
                            />
                            <img
                                src={BtnFourth}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/profit")}
                            />
                            <img
                                src={BtnFifth}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/legal")}
                            />
                            <img
                                src={BtnSixth}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/business")}
                            />
                            <img
                                src={BtnWA}
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                            />
                            <div className="pt-1">
                                <p className="text-base lg:text-lg xl:text-xl">All agents, one WhatsApp line.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AgentsHuman;